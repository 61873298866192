.splashScreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    /* display: flex;s */
    justify-content: center;
    align-items: center;
    background-color: #282c34; /* Background color for the splash screen */
    z-index: 9999;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
  }
  
  .fadeOut {
    opacity: 0;
    visibility: hidden;
  }
  
  .logo {
    animation: scaleUp 5s ease-in-out forwards;
    background: linear-gradient(50deg, #f6a2f6, #7e7ecb, #ee75d2, #13f5fa, #f6a2f6, #f6a2f6); /* Solana palette gradient */
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    perspective: 1000px;
    transform: rotateX(20deg) rotateY(20deg);
  }

  .skip {
    animation: blinkOpacity 3s ease-in-out infinite;
  }
  
  @keyframes scaleUp {
    0% {
      transform: scale(0.8);
      opacity: .8;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }

  @keyframes blinkOpacity {
    0% {
      opacity: 1;
    }
    10% {
      opacity: 0.8;
    }
    20% {
      opacity: 1;
    }
    30% {
      opacity: 0.5;
    }
    40% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    60% {
      opacity: 1;
    }
    70% {
      opacity: .8;
    }
    100% {
      opacity: 1;
    }
  }
  
  .glow {
    color: linear-gradient(50deg, #f6a2f6, #7e7ecb, #ee75d2, #13f5fa, #f6a2f6, #f6a2f6);
    text-shadow: 
      0 0 1px #ffffff41, 
      0 0 2px #fff, 
      0 0 3px #fff, 
      0 0 40px #ee75d2, 
      0 0 70px #13f5fa, 
      0 0 80px #f6a2f6, 
      0 0 100px #13f5fa, 
      0 0 150px #f6a2f6;
  }
  