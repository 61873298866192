.background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    z-index: -1;
    background: url('/bgs/bg_01.png') center center / cover no-repeat; /* Add your background image here */
  }
  
  .svg {
    position: absolute;
    opacity: 0.8;
    width: 80px;
    height: 80px;
    animation: float 10s infinite ease-in-out;
  }
  
  /* Star floating and rotating */
  .star {
    top: 10%;
    left: 20%;
    animation: float 10s infinite ease-in-out, rotate 12s infinite ease-in-out;
  }
  
  /* Cloud drifting */
  .cloud {
    top: 50%;
    left: 70%;
    animation: float 15s infinite ease-in-out, drift 20s infinite ease-in-out;
  }
  
  /* Heart pulsing and floating */
  .heart {
    top: 80%;
    left: 30%;
    animation: float 12s infinite ease-in-out, pulse 5s infinite ease-in-out;
  }
  
  /* Keyframe animations */
  @keyframes float {
    0% {
      transform: translateY(0px) scale(1);
    }
    50% {
      transform: translateY(-20px) scale(1.1);
    }
    100% {
      transform: translateY(0px) scale(1);
    }
  }
  
  @keyframes rotate {
    0% {
        transform: rotate(-15deg);
    }
    50% {
        transform: rotate(80deg);
    }
    100% {
        transform: rotate(-15deg);
    }
  }
  
  @keyframes drift {
    0% {
      transform: translateX(0px);
    }
    100% {
      transform: translateX(-50px);
    }
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
  